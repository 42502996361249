import {
  fetchCultureSelectorData,
  useCultureSelectorData,
} from '@skyscanner-internal/global-components/culture-selector/helpers';
import FooterGC from '@skyscanner-internal/global-components/footer';
import logger from 'saddlebag-logger';

import withErrorBoundary from '../ErrorBoundary/WithErrorBoundary';

import type { FooterProps } from '@skyscanner-internal/falcon-common-types/types/Footer';
import type { FooterProps as FooterGCProps } from '@skyscanner-internal/global-components/footer';

const Footer = (props: FooterProps) => {
  const {
    currencies,
    enMarkets,
    isFetchingCultureSelectorData,
    locales,
    markets,
    onCultureSelectorButtonClick,
  } = useCultureSelectorData(
    fetchCultureSelectorData,
    props.cultureData?.languageShort,
    false,
  );
  const { cultureSelectorEnStrings, cultureSelectorStrings, ...filteredProps } =
    props;
  const footerGCProps: FooterGCProps = {
    ...filteredProps,
    logger,
    cultureSelectorProps: {
      markets,
      locales,
      currencies,
      enMarkets,
      isProductionEnv: true,
      strings: cultureSelectorStrings,
      enStrings: cultureSelectorEnStrings,
    },
    isFetchingCultureSelectorData,
    onCultureSelectorButtonClick,
  };
  return <FooterGC {...footerGCProps} />;
};

const FooterWithErrorBoundary = withErrorBoundary(Footer, 'footer');

export default FooterWithErrorBoundary;
